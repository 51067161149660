import { lazy } from 'react';
import { BiChat } from 'react-icons/bi';
import routes from './routes';

const routesConfig = [
  {
    path: routes.mainPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./MainPage/MainPage.jsx')),
  },
  {
    path: routes.productsByIdPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ProductPage/ProductPage.jsx')),
  },
  {
    path: routes.teamPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./TeamPage/TeamPage.jsx')),
  },
  {
    path: routes.teamByIdPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./TeamPage/PersonPage.jsx')),
  },
  {
    path: routes.eCardInfoPath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ECardPages/ECardInfoPage.jsx')),
  },
  {
    path: routes.servicesPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ServicesPage/ServicesPage.jsx')),
  },
  {
    path: routes.loginPagePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ECardPages/ProfilePage/LoginPage.jsx')),
  },
  {
    path: routes.recoveryPassword(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./RecoveryPage/RecoveryPage.jsx')),
  },
  {
    path: routes.eCardProfilePath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    linkedPaths: [routes.loginPagePath()],
    Component: lazy(() => import('./ECardPages/ProfilePage/ProfilePage.jsx')),
  },
  {
    path: routes.activation(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ECardPages/Activate.jsx')),
  },
  {
    path: routes.approveCard(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ECardPages/ApproveCard.jsx')),
  },
  {
    path: routes.eCardLynxPath(),
    isPrivate: false,
    isExternal: false,
    title: null,
    Icon: null,
    Component: lazy(() => import('./ECardPages/LynxCardsPage/LynxCardsPage')),
  },
  {
    path: routes.contactPagePath(),
    isPrivate: false,
    isExternal: false,
    title: 'nav.contact',
    Icon: BiChat,
    Component: lazy(() => import('./ContactPage/ContactPage.jsx')),
  },
];

export default routesConfig;

export default {
  translation: {
    logo: {
      title: 'Logo company',
    },
    nav: {
      contact: 'Contact',
      eCardProfile: 'Profile',
      dropdown: {
        products: 'Products',
        productsList: {
          whereIsMyChair: "Where's my chair",
          patientDashboard: 'Patient Dashboard',
        },
        services: 'Services',
        team: 'Team',
      },
    },
    languages: {
      ru: 'Русский',
      en: 'English',
      switchIcon: 'Switch language to {{ value }}',
    },
    main: {
      title_0: '<p>Hello</p>',
      title_1: '<p>We are the <tertiary>DTechs</tertiary></p>',
      title_2: "<p>We'd like to talk to you about <secondary>code</secondary> –</p>",
      title_3: "<p>It's what we do</p>",
    },
    forms: {
      errors: {
        required: 'Required',
        min: 'Too short',
        email: 'Not valid',
        whitespace: 'Not valid',
      },
    },
    contact: {
      title: 'Time to talk',
      meetTitle: 'Meet us',
      pitchTitle: 'Pitch us',
      mail: 'office@digitaltechs.io',
      telegram: 'dtechsio',
      phone: '+7 (952) 541 99 27',
      instagram: 'Instagram',
      form: {
        name: 'Name',
        email: 'E-mail',
        topic: 'Topic',
        send: 'Send',
        submittedMessage:
          "{{senderName}}, Welcome to DTechs! We'll contact you as soon as possible",
      },
    },
    products: {
      common: {
        downloadPdfText: "Let's start a new project together",
        downloadPdfButton: 'Download Brochure',
        earlyAccess: 'Early access',
        subscribeMessage: 'Subscribe to the newsletter and receive your early access invite!',
        details: 'Details',
        return: 'Return',
      },
      where_is_my_chair: {
        headline: 'Where’s My Chair?',
        subHeadlines: {
          0: 'Chairs, suites, or booths',
          1: 'Available to local professionals on demand',
          2: 'Reimagine how, when, and where you work',
          3: 'Everything you need to run your salon in one place',
          4: 'Chair rentals. Designed by you, powered by DTechs',
        },
        tags: {
          0: 'Online Booking',
          1: 'Financial Reporting',
          2: 'Client Database',
          3: 'Payment Processing',
          4: 'Appointment Reminders',
          5: 'Mobile App',
        },
        subscribeForm: {
          title: 'Use technology to boost revenue',
          description:
            'Our business management platform helps you rent flexible, secure space to work when and where you need it.',
        },
      },
      patient_dashboard: {
        headline: 'Patient Dashboard',
        subHeadlines: {
          0: 'Header 1',
          1: 'Header 2',
          2: 'Header 3',
          3: 'Header 4',
          4: 'Header 5',
        },
        tags: {
          0: 'Lab results and e-prescriptions',
          1: 'Quick remote consultation',
          2: 'Client Database',
          3: 'Hospital bills payment online',
          4: 'Appointment Reminders',
          5: 'Mobile App',
        },
        subscribeForm: {
          title: 'Use technology to boost revenue',
          description:
            'Our business management platform helps you rent flexible, secure space to work when and where you need it',
        },
      },
    },
    team: {
      FRONTEND: 'FRONTEND',
      BACKEND: 'BACKEND',
      PM: 'PM',
      QA: 'QA',
      soft: {
        teamwork: 'Teamwork',
        interviewing: 'Reviewing the code',
        reviewing: 'Interviewing & onboarding',
        mentoring: 'Mentoring and supporting',
        workflow: 'Organizing the workflow',
        tea: 'Cups of tea/coffee drunk',
      },
      QandA: {
        song: 'Name your go-to song.',
        book: "The best book you've ever read?",
        movie: 'What series/movie are you obsessed with?',
        snack: "One snack we'd always find in your cupboard?",
        hobby: 'Can you share your top hobby?',
        before40: 'One thing to do before turning 40?',
        workflow: 'What is the best part of your workflow?',
        inspires: 'What inspires you to work in this field?',
        describe: 'Describe the purpose of your job to a five-year-old.',
        learn: 'What are new skills you hope to learn in six months?',
        apps: 'Are there any apps you use to sharpen your skills?',
        gift: 'What was the last gift you gave someone?',
        place: 'What is your happy place?',
      },
    },
    eCard: {
      cardEdit: 'Edit card',
      cardCheck: 'Check my card',
      updateCard: 'Update card',
      activateCard: 'Activate card',
      addPhoto: 'Take or upload a photo',
      photoError: 'There was en error loading',
      login: {
        logIn: 'Log in',
        signUp: 'Sign up',
        forgotPassword: 'Recovery password',
        login: 'Login',
        password: 'Password',
        email: 'E-mail',
        whatsApp: 'WhatsApp number',
        changeToSignUp: 'Sign up',
        changeToLogIn: 'Log in',
        changeToForgotPassword: 'Forgot password?',
        or: 'or',
      },
      recovery: {
        recoveryHint: 'Enter your email address or WhatsApp number',
        recoveryPasswordHint: 'Enter a new password',
        message: 'The link has been sent to you',
        whatsApp: ' in whatsapp',
        email: 'r email',
      },
      lynx: {
        activeCard: 'activated',
        notActiveCard: 'not activated',
      },
      addContact: 'Add to Contacts',
      profile: {
        menu: {
          settings: 'Settings',
          editProfile: 'Edit profile',
          lynx: 'Lynx',
          security: 'Security',
        },
        userForm: {
          titles: {
            userForm: "What's your name?",
            addPhoto: 'Cheeese!',
            usernameData: 'Login data',
            social: 'Social media and messengers',
            contacts: 'how to reach you?',
            others: 'Other',
          },
          firstName: 'First name',
          lastName: 'Last name',
          companyName: 'Company',
          post: 'Post',
          email: 'eMail',
          phone: 'Phone number',
          site: 'Site',
          password: 'password',
          username: 'username',
          accountLink: 'link to an existing account',
          redirectLink: 'Redirect link',
          needRedirect: 'Redirect',
          redirectInfo: 'your site will appear when scanning',
          locale: 'language',
        },
        passwordForm: {
          oldPassword: 'Old Password',
          newPassword: 'New Password',
          repeatPassword: 'Repeat Password',
        },
      },
    },
    notifications: {
      error: 'Error',
      loginError: 'Invalid login or password',
      passwordChangeOk: 'Password change - success',
      success: 'Success!',
    },
    general: {
      back: 'Back',
      submit: 'Submit',
      save: 'Save',
      error: 'Error!',
    },
    service: 'Got a question? Text us!',
  },
};

const routes = {
  mainPagePath: () => '/',
  contactPagePath: () => '/contact',
  productsPagePath: () => '/products',
  productsByIdPagePath: () => `/products/:id`,
  teamPagePath: () => '/team',
  teamByIdPagePath: () => `/team/:id`,
  servicesPagePath: () => '/services',
  eCardProfilePath: () => '/smartCard/profile',
  loginPagePath: () => '/auth/login',
  recoveryPassword: () => `/forgotPassword/:token`,
  activation: () => '/smartCard/activate/:id',
  eCardInfo: () => '/smartCard/info',
  eCardInfoPath: () => '/smartCard/info/:uuid',
  approveCard: () => '/smartCard/approveCard/:id',
  eCardLynxPath: () => '/smartCard/lynx/cards',
  apiURL: 'https://dtechs.io/api/',
};
export default routes;

import axios from 'axios';
import { AuthFormData, AuthLoginData } from '../types/AuthTypes';
import authMap from '../models/AuthMap';
import getEnvs from '../../../../env/env';

const { api } = getEnvs();

const registerUser = (formData: AuthFormData): Promise<any> => {
  return axios.post(`${api}auth/register`, formData);
};

const loginUser = (loginData: AuthLoginData): Promise<any> => {
  return axios.post(`${api}auth/login`, loginData);
};

const forgotPassword = (loginData: { restoreType: string; contact: string }): Promise<any> => {
  return axios.post(`${api}forgotPassword/link`, loginData);
};

const recoveryPassword = (
  recoveryData: {
    newPassword: string;
    confirmation: string;
  },
  token: string,
): Promise<any> => {
  return axios.post(`${api}forgotPassword?token=${token}`, recoveryData);
};

const logOut = () => {
  Object.keys(authMap).map((key) => localStorage.removeItem(key));
};

export { registerUser, loginUser, logOut, forgotPassword, recoveryPassword };
